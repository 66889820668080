import { createContext } from 'react';
import { RequestState } from '../../utils/request-state';
import { User } from '../../@types/user';
import { UserCredential } from 'firebase/auth';

export interface AuthContextProps {
  user?: User;
  initialized?: boolean;
  firebaseUser?: UserCredential['user'] | null;
  customFirebaseUser?: UserCredential['user'] | null;
  admin?: boolean;
  requestGetUser: RequestState;
  requestSignIn: RequestState;
  requestSignUp: RequestState;
  requestSignOut: RequestState;
  requestUpdatePassword: RequestState;
  requestUpdateDisplayName: RequestState;
  requestPasswordResetEmail: RequestState;
  requestConfirmPasswordReset: RequestState;
  requestSendEmailVerification: RequestState;
  requestSignInCustom: RequestState;
  requestGetAdminStatus: RequestState;
  getUser: () => void;
  getToken: () => Promise<string | null>;
  signIn: (email: string, password: string) => void;
  signUp: (email: string, password: string, fName: string, lName: string) => void;
  signOut: () => void;
  updatePassword: (newPassword: string) => void;
  updateDisplayName: (displayName: string) => void;
  sendPasswordResetEmail: (email: string) => void;
  confirmPasswordReset: (code: string, password: string) => void;
  sendEmailVerification: () => void;
  signInCustom: (token: string) => void;
}
export const AuthContext = createContext<AuthContextProps | undefined>(undefined);
