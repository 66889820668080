import { UserCredential } from 'firebase/auth';

import { BaseAction } from '../../@types/Action';
import { User } from '../../@types/user';
import {
  RequestError,
  requestError,
  requestIdle,
  requestInit,
  RequestState,
  requestSuccessful,
} from '../../utils/request-state';

export const REQ_GET_USER = 'REQ_GET_USER';
export const RCV_GET_USER = 'RCV_GET_USER';
export const ERR_GET_USER = 'ERR_GET_USER';

type REQ_GET_USER_TYPE = BaseAction<typeof REQ_GET_USER>;
type RCV_GET_USER_TYPE = BaseAction<typeof RCV_GET_USER> & {
  user: User;
};
type ERR_GET_USER_TYPE = BaseAction<typeof ERR_GET_USER> & {
  error?: RequestError;
};

export const REQ_SIGN_IN = 'REQ_SIGN_IN';
export const RCV_SIGN_IN = 'RCV_SIGN_IN';
export const ERR_SIGN_IN = 'ERR_SIGN_IN';

type REQ_SIGN_IN_TYPE = BaseAction<typeof REQ_SIGN_IN>;
type RCV_SIGN_IN_TYPE = BaseAction<typeof RCV_SIGN_IN> & {
  firebaseUser?: UserCredential['user'];
};
type ERR_SIGN_IN_TYPE = BaseAction<typeof ERR_SIGN_IN> & {
  error?: RequestError;
};

export const REQ_SIGN_UP = 'REQ_SIGN_UP';
export const RCV_SIGN_UP = 'RCV_SIGN_UP';
export const ERR_SIGN_UP = 'ERR_SIGN_UP';

type REQ_SIGN_UP_TYPE = BaseAction<typeof REQ_SIGN_UP>;
type RCV_SIGN_UP_TYPE = BaseAction<typeof RCV_SIGN_UP> & {
  firebaseUser?: UserCredential['user'];
};
type ERR_SIGN_UP_TYPE = BaseAction<typeof ERR_SIGN_UP> & {
  error?: RequestError;
};

export const REQ_SIGN_OUT = 'REQ_SIGN_OUT';
export const RCV_SIGN_OUT = 'RCV_SIGN_OUT';
export const ERR_SIGN_OUT = 'ERR_SIGN_OUT';

type REQ_SIGN_OUT_TYPE = BaseAction<typeof REQ_SIGN_OUT>;
type RCV_SIGN_OUT_TYPE = BaseAction<typeof RCV_SIGN_OUT>;
type ERR_SIGN_OUT_TYPE = BaseAction<typeof ERR_SIGN_OUT>;

export const REQ_UPDATE_PASSWORD = 'REQ_UPDATE_PASSWORD';
export const RCV_UPDATE_PASSWORD = 'RCV_UPDATE_PASSWORD';
export const ERR_UPDATE_PASSWORD = 'ERR_UPDATE_PASSWORD';

type REQ_UPDATE_PASSWORD_TYPE = BaseAction<typeof REQ_UPDATE_PASSWORD>;
type RCV_UPDATE_PASSWORD_TYPE = BaseAction<typeof RCV_UPDATE_PASSWORD>;
type ERR_UPDATE_PASSWORD_TYPE = BaseAction<typeof ERR_UPDATE_PASSWORD> & {
  error?: RequestError;
};

export const REQ_UPDATE_DISPLAY_NAME = 'REQ_UPDATE_DISPLAY_NAME';
export const RCV_UPDATE_DISPLAY_NAME = 'RCV_UPDATE_DISPLAY_NAME';
export const ERR_UPDATE_DISPLAY_NAME = 'ERR_UPDATE_DISPLAY_NAME';

type REQ_UPDATE_DISPLAY_NAME_TYPE = BaseAction<typeof REQ_UPDATE_DISPLAY_NAME>;
type RCV_UPDATE_DISPLAY_NAME_TYPE = BaseAction<typeof RCV_UPDATE_DISPLAY_NAME>;
type ERR_UPDATE_DISPLAY_NAME_TYPE = BaseAction<typeof ERR_UPDATE_DISPLAY_NAME>;

export const REQ_PASSWORD_RESET_EMAIL = 'REQ_PASSWORD_RESET_EMAIL';
export const RCV_PASSWORD_RESET_EMAIL = 'RCV_PASSWORD_RESET_EMAIL';
export const ERR_PASSWORD_RESET_EMAIL = 'ERR_PASSWORD_RESET_EMAIL';

type REQ_PASSWORD_RESET_EMAIL_TYPE = BaseAction<typeof REQ_PASSWORD_RESET_EMAIL>;
type RCV_PASSWORD_RESET_EMAIL_TYPE = BaseAction<typeof RCV_PASSWORD_RESET_EMAIL>;
type ERR_PASSWORD_RESET_EMAIL_TYPE = BaseAction<typeof ERR_PASSWORD_RESET_EMAIL>;

export const REQ_CONFIRM_PASSWORD_RESET = 'REQ_CONFIRM_PASSWORD_RESET';
export const RCV_CONFIRM_PASSWORD_RESET = 'RCV_CONFIRM_PASSWORD_RESET';
export const ERR_CONFIRM_PASSWORD_RESET = 'ERR_CONFIRM_PASSWORD_RESET';

type REQ_CONFIRM_PASSWORD_RESET_TYPE = BaseAction<typeof REQ_CONFIRM_PASSWORD_RESET>;
type RCV_CONFIRM_PASSWORD_RESET_TYPE = BaseAction<typeof RCV_CONFIRM_PASSWORD_RESET>;
type ERR_CONFIRM_PASSWORD_RESET_TYPE = BaseAction<typeof ERR_CONFIRM_PASSWORD_RESET>;

export const REQ_SEND_EMAIL_VERIFICATION = 'REQ_SEND_EMAIL_VERIFICATION';
export const RCV_SEND_EMAIL_VERIFICATION = 'RCV_SEND_EMAIL_VERIFICATION';
export const ERR_SEND_EMAIL_VERIFICATION = 'ERR_SEND_EMAIL_VERIFICATION';

type REQ_SEND_EMAIL_VERIFICATION_TYPE = BaseAction<typeof REQ_SEND_EMAIL_VERIFICATION>;
type RCV_SEND_EMAIL_VERIFICATION_TYPE = BaseAction<typeof RCV_SEND_EMAIL_VERIFICATION>;
type ERR_SEND_EMAIL_VERIFICATION_TYPE = BaseAction<typeof ERR_SEND_EMAIL_VERIFICATION> & {
  error?: RequestError;
};

export const REQ_SIGN_IN_CUSTOM = 'REQ_SIGN_IN_CUSTOM';
export const RCV_SIGN_IN_CUSTOM = 'RCV_SIGN_IN_CUSTOM';
export const ERR_SIGN_IN_CUSTOM = 'ERR_SIGN_IN_CUSTOM';

type REQ_SIGN_IN_CUSTOM_TYPE = BaseAction<typeof REQ_SIGN_IN_CUSTOM>;
type RCV_SIGN_IN_CUSTOM_TYPE = BaseAction<typeof RCV_SIGN_IN_CUSTOM> & {
  customFirebaseUser?: UserCredential['user'];
};
type ERR_SIGN_IN_CUSTOM_TYPE = BaseAction<typeof ERR_SIGN_IN_CUSTOM>;

export const REQ_GET_ADMIN_STATUS = 'REQ_GET_ADMIN_STATUS';
export const RCV_GET_ADMIN_STATUS = 'RCV_GET_ADMIN_STATUS';
export const ERR_GET_ADMIN_STATUS = 'ERR_GET_ADMIN_STATUS';

type REQ_GET_ADMIN_STATUS_TYPE = BaseAction<typeof REQ_GET_ADMIN_STATUS>;
type RCV_GET_ADMIN_STATUS_TYPE = BaseAction<typeof RCV_GET_ADMIN_STATUS> & {
  admin: boolean;
};
type ERR_GET_ADMIN_STATUS_TYPE = BaseAction<typeof ERR_GET_ADMIN_STATUS>;

export const SET_ADMIN_STATUS = 'SET_ADMIN_STATUS';
type SET_ADMIN_STATUS_TYPE = BaseAction<typeof SET_ADMIN_STATUS> & {
  admin: boolean;
};

export const SET_USER = 'SET_USER';
type SET_USER_TYPE = BaseAction<typeof SET_USER> & {
  firebaseUser?: UserCredential['user'];
};

export const SET_INITIALIZED = 'SET_INITIALIZED';
type SET_INITIALIZED_TYPE = BaseAction<typeof SET_INITIALIZED>;

type AuthActions =
  | REQ_GET_USER_TYPE
  | RCV_GET_USER_TYPE
  | ERR_GET_USER_TYPE
  | REQ_SIGN_IN_TYPE
  | RCV_SIGN_IN_TYPE
  | ERR_SIGN_IN_TYPE
  | REQ_SIGN_UP_TYPE
  | RCV_SIGN_UP_TYPE
  | ERR_SIGN_UP_TYPE
  | REQ_SIGN_OUT_TYPE
  | RCV_SIGN_OUT_TYPE
  | ERR_SIGN_OUT_TYPE
  | REQ_UPDATE_PASSWORD_TYPE
  | RCV_UPDATE_PASSWORD_TYPE
  | ERR_UPDATE_PASSWORD_TYPE
  | REQ_UPDATE_DISPLAY_NAME_TYPE
  | RCV_UPDATE_DISPLAY_NAME_TYPE
  | ERR_UPDATE_DISPLAY_NAME_TYPE
  | REQ_PASSWORD_RESET_EMAIL_TYPE
  | RCV_PASSWORD_RESET_EMAIL_TYPE
  | ERR_PASSWORD_RESET_EMAIL_TYPE
  | REQ_CONFIRM_PASSWORD_RESET_TYPE
  | RCV_CONFIRM_PASSWORD_RESET_TYPE
  | ERR_CONFIRM_PASSWORD_RESET_TYPE
  | REQ_SEND_EMAIL_VERIFICATION_TYPE
  | RCV_SEND_EMAIL_VERIFICATION_TYPE
  | ERR_SEND_EMAIL_VERIFICATION_TYPE
  | REQ_SIGN_IN_CUSTOM_TYPE
  | RCV_SIGN_IN_CUSTOM_TYPE
  | ERR_SIGN_IN_CUSTOM_TYPE
  | REQ_GET_ADMIN_STATUS_TYPE
  | RCV_GET_ADMIN_STATUS_TYPE
  | ERR_GET_ADMIN_STATUS_TYPE
  | SET_ADMIN_STATUS_TYPE
  | SET_USER_TYPE
  | SET_INITIALIZED_TYPE;

interface AuthState {
  user?: User;
  initialized?: boolean;
  firebaseUser?: UserCredential['user'] | null;
  customFirebaseUser?: UserCredential['user'] | null;
  admin?: boolean;

  requestGetUser: RequestState;
  requestSignIn: RequestState;
  requestSignUp: RequestState;
  requestSignOut: RequestState;
  requestUpdatePassword: RequestState;
  requestUpdateDisplayName: RequestState;
  requestPasswordResetEmail: RequestState;
  requestConfirmPasswordReset: RequestState;
  requestSendEmailVerification: RequestState;
  requestSignInCustom: RequestState;
  requestGetAdminStatus: RequestState;
}

export const initialState: AuthState = {
  requestGetUser: requestIdle(),
  requestSignIn: requestIdle(),
  requestSignUp: requestIdle(),
  requestSignOut: requestIdle(),
  requestUpdatePassword: requestIdle(),
  requestUpdateDisplayName: requestIdle(),
  requestPasswordResetEmail: requestIdle(),
  requestConfirmPasswordReset: requestIdle(),
  requestSendEmailVerification: requestIdle(),
  requestSignInCustom: requestIdle(),
  requestGetAdminStatus: requestIdle(),
};

const authReducer = (state: AuthState, action: AuthActions) => {
  const next = { ...state };
  switch (action.type) {
    case REQ_GET_USER:
      next.requestGetUser = requestInit();
      return next;
    case RCV_GET_USER:
      next.requestGetUser = requestSuccessful();
      next.user = action.user;
      return next;
    case ERR_GET_USER:
      next.requestGetUser = requestError(action.error);
      return next;
    case REQ_SIGN_IN:
      next.requestSignIn = requestInit();
      return next;
    case RCV_SIGN_IN:
      next.requestSignIn = requestSuccessful();
      next.firebaseUser = action.firebaseUser;
      return next;
    case ERR_SIGN_IN:
      next.requestSignIn = requestError(action.error);
      return next;
    case REQ_SIGN_UP:
      next.requestSignUp = requestInit();
      return next;
    case RCV_SIGN_UP:
      next.requestSignUp = requestSuccessful();
      next.firebaseUser = action.firebaseUser;
      return next;
    case ERR_SIGN_UP:
      next.requestSignUp = requestError(action.error);
      return next;
    case REQ_SIGN_OUT:
      next.requestSignOut = requestInit();
      return next;
    case RCV_SIGN_OUT:
      next.requestSignOut = requestSuccessful();
      next.firebaseUser = undefined;
      next.admin = undefined;
      return next;
    case ERR_SIGN_OUT:
      next.requestSignOut = requestError();
      return next;
    case REQ_UPDATE_PASSWORD:
      next.requestUpdatePassword = requestInit();
      return next;
    case RCV_UPDATE_PASSWORD:
      next.requestUpdatePassword = requestSuccessful();
      return next;
    case ERR_UPDATE_PASSWORD:
      next.requestUpdatePassword = requestError(action.error);
      return next;
    case REQ_UPDATE_DISPLAY_NAME:
      next.requestUpdateDisplayName = requestInit();
      return next;
    case RCV_UPDATE_DISPLAY_NAME:
      next.requestUpdateDisplayName = requestSuccessful();
      return next;
    case ERR_UPDATE_DISPLAY_NAME:
      next.requestUpdateDisplayName = requestError();
      return next;
    case REQ_PASSWORD_RESET_EMAIL:
      next.requestPasswordResetEmail = requestInit();
      return next;
    case RCV_PASSWORD_RESET_EMAIL:
      next.requestPasswordResetEmail = requestSuccessful();
      return next;
    case ERR_PASSWORD_RESET_EMAIL:
      next.requestPasswordResetEmail = requestError();
      return next;
    case REQ_CONFIRM_PASSWORD_RESET:
      next.requestConfirmPasswordReset = requestInit();
      return next;
    case RCV_CONFIRM_PASSWORD_RESET:
      next.requestConfirmPasswordReset = requestSuccessful();
      return next;
    case ERR_CONFIRM_PASSWORD_RESET:
      next.requestConfirmPasswordReset = requestError();
      return next;
    case REQ_SEND_EMAIL_VERIFICATION:
      next.requestSendEmailVerification = requestInit();
      return next;
    case RCV_SEND_EMAIL_VERIFICATION:
      next.requestSendEmailVerification = requestSuccessful();
      return next;
    case ERR_SEND_EMAIL_VERIFICATION:
      next.requestSendEmailVerification = requestError(action.error);
      return next;
    case REQ_SIGN_IN_CUSTOM:
      next.requestSignInCustom = requestInit();
      next.customFirebaseUser = undefined;
      return next;
    case RCV_SIGN_IN_CUSTOM:
      next.requestSignInCustom = requestSuccessful();
      next.customFirebaseUser = action.customFirebaseUser;
      return next;
    case ERR_SIGN_IN_CUSTOM:
      next.requestSignInCustom = requestError();
      return next;

    case REQ_GET_ADMIN_STATUS:
      next.requestGetAdminStatus = requestInit();
      return next;
    case RCV_GET_ADMIN_STATUS:
      next.requestGetAdminStatus = requestSuccessful();
      next.admin = action.admin;
      return next;
    case ERR_GET_ADMIN_STATUS:
      next.requestGetAdminStatus = requestError();
      next.admin = false;
      return next;
    case SET_ADMIN_STATUS:
      next.admin = action.admin;
      return next;
    case SET_USER:
      next.firebaseUser = action.firebaseUser;
      return next;
    case SET_INITIALIZED:
      next.initialized = true;
      return next;
    default:
      throw new Error(`Unsupported action type`);
  }
};

export default authReducer;
