import styled from 'styled-components';

const BimxplorerLogo = styled.div`
  background-image: url(../../images/bim-navigation.png);
  margin-top: 10px;
  height: 40px;
  width: 210px;
  background-size: contain;
  background-repeat: no-repeat;
`;

export default BimxplorerLogo;
