export interface RequestError {
  code: string | number;
  message: string;
}

export interface RequestState {
  loading: boolean;
  successful: boolean;
  error: boolean;
  errorCode?: number | string;
  errorMessage?: string;
}

export const requestIdle = (): RequestState => ({
  loading: false,
  successful: false,
  error: false,
});

export const requestInit = (): RequestState => ({
  loading: true,
  successful: false,
  error: false,
});

export const requestSuccessful = (): RequestState => ({
  loading: false,
  successful: true,
  error: false,
});

export const requestError = (error?: RequestError): RequestState => ({
  loading: false,
  successful: false,
  error: true,
  errorCode: error?.code,
  errorMessage: error?.message,
});

export interface RequestCluster {
  [key: string]: RequestState | undefined;
}

export const requestClusterUpdate = (
  cluster: RequestCluster,
  id: string,
  method: () => RequestState
) => ({
  ...cluster,
  [id]: method(),
});
