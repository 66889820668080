import { BaseAction } from '../../@types/Action';
import { AdminUsersData, RedisAccount } from '../../@types/Redis';
import {
  RequestError,
  requestError,
  requestIdle,
  requestInit,
  RequestState,
  requestSuccessful,
} from '../../utils/request-state';

export const REQ_GET_ADMIN_REDIS_STATUS = 'REQ_GET_ADMIN_REDIS_STATUS';
export const RCV_GET_ADMIN_REDIS_STATUS = 'RCV_GET_ADMIN_REDIS_STATUS';
export const ERR_GET_ADMIN_REDIS_STATUS = 'ERR_GET_ADMIN_REDIS_STATUS';

type REQ_GET_ADMIN_REDIS_STATUS_TYPE = BaseAction<typeof REQ_GET_ADMIN_REDIS_STATUS>;
type RCV_GET_ADMIN_REDIS_STATUS_TYPE = BaseAction<typeof RCV_GET_ADMIN_REDIS_STATUS> & {
  accounts: RedisAccount[];
};
type ERR_GET_ADMIN_REDIS_STATUS_TYPE = BaseAction<typeof ERR_GET_ADMIN_REDIS_STATUS> & {
  error?: RequestError;
};

export const REQ_ADMIN_GET_USER = 'REQ_ADMIN_GET_USER';
export const RCV_ADMIN_GET_USER = 'RCV_ADMIN_GET_USER';
export const ERR_ADMIN_GET_USER = 'ERR_ADMIN_GET_USER';

type REQ_ADMIN_GET_USER_TYPE = BaseAction<typeof REQ_ADMIN_GET_USER>;
type RCV_ADMIN_GET_USER_TYPE = BaseAction<typeof RCV_ADMIN_GET_USER> & {
  usersData: AdminUsersData;
};
type ERR_ADMIN_GET_USER_TYPE = BaseAction<typeof ERR_ADMIN_GET_USER> & {
  error?: RequestError;
};

export const REQ_ADMIN_UPDATE_USER = 'REQ_ADMIN_UPDATE_USER';
export const RCV_ADMIN_UPDATE_USER = 'RCV_ADMIN_UPDATE_USER';
export const ERR_ADMIN_UPDATE_USER = 'ERR_ADMIN_UPDATE_USER';

type REQ_ADMIN_UPDATE_USER_TYPE = BaseAction<typeof REQ_ADMIN_UPDATE_USER>;
type RCV_ADMIN_UPDATE_USER_TYPE = BaseAction<typeof RCV_ADMIN_UPDATE_USER>;
type ERR_ADMIN_UPDATE_USER_TYPE = BaseAction<typeof ERR_ADMIN_UPDATE_USER> & {
  error?: RequestError;
};

type RedisActions =
  | REQ_GET_ADMIN_REDIS_STATUS_TYPE
  | RCV_GET_ADMIN_REDIS_STATUS_TYPE
  | ERR_GET_ADMIN_REDIS_STATUS_TYPE
  | REQ_ADMIN_GET_USER_TYPE
  | RCV_ADMIN_GET_USER_TYPE
  | ERR_ADMIN_GET_USER_TYPE
  | REQ_ADMIN_UPDATE_USER_TYPE
  | RCV_ADMIN_UPDATE_USER_TYPE
  | ERR_ADMIN_UPDATE_USER_TYPE;

interface RedisState {
  accounts?: RedisAccount[];
  usersData?: AdminUsersData;
  requestGet: RequestState;
  requestGetUser: RequestState;
  requestUpdateUser: RequestState;
}

export const initialState: RedisState = {
  requestGet: requestIdle(),
  requestGetUser: requestIdle(),
  requestUpdateUser: requestIdle(),
};

const redisReducer = (state: RedisState, action: RedisActions) => {
  const next = { ...state };
  switch (action.type) {
    case REQ_GET_ADMIN_REDIS_STATUS:
      next.requestGet = requestInit();
      return next;
    case RCV_GET_ADMIN_REDIS_STATUS:
      next.accounts = action.accounts;
      next.requestGet = requestSuccessful();
      return next;
    case ERR_GET_ADMIN_REDIS_STATUS:
      next.requestGet = requestError(action.error);
      return next;
    case REQ_ADMIN_GET_USER:
      next.requestGetUser = requestInit();
      return next;
    case RCV_ADMIN_GET_USER:
      next.requestGetUser = requestSuccessful();
      next.usersData = action.usersData;
      return next;
    case ERR_ADMIN_GET_USER:
      next.requestGetUser = requestError(action.error);
      return next;
    case REQ_ADMIN_UPDATE_USER:
      next.requestUpdateUser = requestInit();
      return next;
    case RCV_ADMIN_UPDATE_USER:
      next.requestUpdateUser = requestSuccessful();
      return next;
    case ERR_ADMIN_UPDATE_USER:
      next.requestUpdateUser = requestError(action.error);
      return next;
    default:
      throw new Error(`Unsupported action type`);
  }
};

export default redisReducer;
