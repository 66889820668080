import styled from 'styled-components';
import { Drawer as MUDrawer } from '@material-ui/core';

const Drawer = styled(MUDrawer)`
  display: none;
  @media (max-width: 959px) {
    display: block;
  }

  @media (max-width: 599px) {
  }
`;

export default Drawer;
