import React from 'react';
import YouTubeIcon from '@material-ui/icons/YouTube';

import { useStaticQuery, graphql } from 'gatsby';
import { Container, Grid, Typography, Box, Divider, IconButton } from '@material-ui/core';

import DefaultLink from '../DefaultLink';
import Copyright from '../Copyright';
import FooterList from './FooterList';
import FooterListItem from './FooterListItem';
import ButtonContainer from '../Container/ButtonContainer';

import { FooterQuery } from '../../../types/graphql-types';

interface FooterChild {
  title: string;
  to: string;
}

interface FooterItem {
  title: string;
  children: FooterChild[];
}

const Footers: FooterItem[] = [
  {
    title: 'Company',
    children: [
      { title: 'Contact', to: '/contact' },
      { title: 'About', to: '/about' },
    ],
  },

  {
    title: 'Product',
    children: [{ title: 'Blog', to: '/blog' }],
  },
  {
    title: 'Resources',
    children: [
      { title: 'Download', to: '/download' },
      { title: 'Releases', to: '/releases' },
    ],
  },
  {
    title: 'Legal',
    children: [],
  },
];

export const Footer = (): JSX.Element => {
  const data = useStaticQuery<FooterQuery>(graphql`
    query Footer {
      site {
        siteMetadata {
          title
        }
      }
      allContentfulLegal(sort: { fields: [order], order: DESC }) {
        edges {
          node {
            title
            slug
          }
        }
      }
      contentfulPricing {
        title
        slug
      }
      contentfulPricing {
        title
        slug
      }
      contentfulDevelopment {
        title
        slug
      }
      contentfulTeam {
        title
        slug
      }
    }
  `);

  const footers = React.useMemo(() => {
    const nextFooter: FooterItem[] = JSON.parse(JSON.stringify(Footers));

    if (data?.contentfulTeam) {
      nextFooter[0].children = [
        ...nextFooter[0].children,
        {
          title: data?.contentfulTeam?.title as string,
          to: '/team',
        },
      ];
    }

    if (data?.contentfulPricing) {
      nextFooter[1].children = [
        ...nextFooter[1].children,
        {
          title: data?.contentfulPricing?.title as string,
          to: '/pricing',
        },
      ];
    }

    if (data?.contentfulDevelopment) {
      nextFooter[1].children = [
        ...nextFooter[1].children,
        {
          title: data?.contentfulDevelopment?.title as string,
          to: '/development',
        },
      ];
    }

    if (data?.allContentfulLegal?.edges) {
      nextFooter[3].children = [
        ...nextFooter[3].children,
        ...data?.allContentfulLegal?.edges?.map((legal) => ({
          title: legal.node.title as string,
          to: `legal/${legal.node.slug as string}`,
        })),
      ];
    }

    return nextFooter;
  }, [data]);

  return (
    <>
      <Box mt={5} mb={5}>
        <Divider />
        <Container>
          <Box mt={1} mb={1}>
            <ButtonContainer>
              <IconButton
                href="https://www.youtube.com/channel/UC5Jjw_XDNDlbrq1iCrt-B-Q"
                target="_blank"
                rel="noopener noreferrer"
                title="Youtube - Bimxplorer"
              >
                <YouTubeIcon />
              </IconButton>
            </ButtonContainer>
          </Box>
        </Container>
        <Divider />
      </Box>
      <Box mt={5}>
        <Container component="footer">
          <Grid container spacing={4} justifyContent="space-evenly">
            {footers.map((footer) => (
              <Grid item xs={6} sm={2} key={footer.title}>
                <Typography variant="h6" color="textPrimary" gutterBottom>
                  {footer.title}
                </Typography>
                <FooterList>
                  {footer.children.map((item) => (
                    <FooterListItem key={item.title}>
                      <DefaultLink href={item.to} variant="subtitle1" color="textSecondary">
                        {item.title}
                      </DefaultLink>
                    </FooterListItem>
                  ))}
                </FooterList>
              </Grid>
            ))}
          </Grid>

          <Box mt={5}>
            <Copyright />
          </Box>
        </Container>
      </Box>
    </>
  );
};
