import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import Header from './header';
import { Footer } from './Footer';

export interface AccountModel {
  displayName: string;
  email: string;
  emailVerified: boolean;
  token: string;
}

export interface Layout {
  children: JSX.Element;
}

const Layout = ({ children }: Layout): JSX.Element => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <>
      <Header siteTitle={data.site.siteMetadata.title} />
      {children}
      <Footer />
    </>
  );
};

export default Layout;
