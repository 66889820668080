import React from 'react';
import { Link } from 'gatsby';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import MenuIcon from '@material-ui/icons/Menu';
import GetAppIcon from '@material-ui/icons/GetApp';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import MailIcon from '@material-ui/icons/Mail';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';

import Desktop from './desktop';
import Mobile from './mobile';
import BimxplorerLogo from '../Logo/BimxplorerLogo';
import DrawerButton from '../IconButton/DrawerButton';

export interface LinkItem {
  title: string;
  to: string;
  icon: JSX.Element;
}

const Links: LinkItem[] = [
  {
    title: 'Download',
    to: '/download',
    icon: <GetAppIcon />,
  },
  {
    title: 'Blog',
    to: '/blog',
    icon: <LibraryBooksIcon />,
  },
  {
    title: 'Contact',
    to: '/contact',
    icon: <MailIcon />,
  },
  {
    title: 'Releases',
    to: '/releases',
    icon: <LocalOfferIcon />,
  },
];

export interface Header {
  siteTitle: string;
}

const Header = ({}: Header): JSX.Element => {
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <AppBar position="relative" color="default">
        <Toolbar>
          <DrawerButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={(): void => setOpen(!open)}
          >
            <MenuIcon />
          </DrawerButton>
          <Link
            title="Home"
            to="/"
            style={{
              color: 'inherit',
              textDecoration: `none`,
              flexGrow: 1,
            }}
          >
            <BimxplorerLogo />
          </Link>
          <Desktop links={Links} />
        </Toolbar>
      </AppBar>
      <Mobile links={Links} open={open} setOpen={setOpen} />
    </>
  );
};

export default Header;
