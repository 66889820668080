import React from 'react';

import { IconButton, Button, MenuItem, Menu } from '@material-ui/core';
import styled from 'styled-components';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { LinkItem } from '.';
import DefaultLink from '../DefaultLink';
import useAuth from '../../State/Auth/useAuth';

const StyledDesktopLinks = styled.div`
  display: none;
  @media (min-width: 959px) {
    display: inherit;
  }
`;

interface Desktop {
  links: LinkItem[];
}

const Desktop = ({ links }: Desktop): JSX.Element => {
  const auth = useAuth();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleMenu = (event: React.MouseEvent<HTMLElement>): void =>
    setAnchorEl(event.currentTarget);

  const handleClose = (): void => setAnchorEl(null);

  return (
    <StyledDesktopLinks>
      {links.map(({ title, to }) => (
        <DefaultLink
          key={to}
          href={to}
          style={{
            color: 'inherit',
            textDecoration: `none`,
            margin: '8px 12px',
          }}
        >
          <Button>{title}</Button>
        </DefaultLink>
      ))}
      {auth.firebaseUser ? (
        <>
          <div>
            <IconButton
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              <AccountCircleIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={handleClose}>
                <DefaultLink
                  href="/account"
                  style={{
                    color: 'inherit',
                    textDecoration: `none`,
                  }}
                >
                  Account
                </DefaultLink>
              </MenuItem>
              <MenuItem
                onClick={(): void => {
                  handleClose();
                  auth.signOut();
                }}
              >
                Sign out
              </MenuItem>
            </Menu>
          </div>
        </>
      ) : (
        <DefaultLink
          href="/login"
          style={{
            color: 'inherit',
            textDecoration: `none`,
            margin: '8px 12px',
          }}
        >
          <Button>Login</Button>
        </DefaultLink>
      )}
    </StyledDesktopLinks>
  );
};

export default Desktop;
