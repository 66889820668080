import React, { useReducer, useMemo, useCallback, useEffect } from 'react';

import { initializeApp } from 'firebase/app';
import {
  getAuth,
  setPersistence,
  signInWithEmailAndPassword,
  browserSessionPersistence,
  createUserWithEmailAndPassword,
  updatePassword as updatePasswordFirebase,
  updateProfile,
  sendPasswordResetEmail as sendPasswordResetEmailFirebase,
  confirmPasswordReset as confirmPasswordResetFirebase,
  sendEmailVerification as sendEmailVerificationFirebase,
  signInWithCustomToken as signInWithCustomTokenFirebase,
  getIdTokenResult,
  onAuthStateChanged as onAuthStateChangedFirebase,
} from 'firebase/auth';

import { AuthContext, AuthContextProps } from './AuthContext';
import reducer, {
  initialState,
  REQ_GET_USER,
  RCV_GET_USER,
  ERR_GET_USER,
  REQ_SIGN_IN,
  ERR_SIGN_IN,
  RCV_SIGN_IN,
  REQ_SIGN_OUT,
  RCV_SIGN_OUT,
  ERR_SIGN_OUT,
  REQ_UPDATE_PASSWORD,
  RCV_UPDATE_PASSWORD,
  ERR_UPDATE_PASSWORD,
  REQ_UPDATE_DISPLAY_NAME,
  ERR_UPDATE_DISPLAY_NAME,
  RCV_UPDATE_DISPLAY_NAME,
  REQ_PASSWORD_RESET_EMAIL,
  RCV_PASSWORD_RESET_EMAIL,
  ERR_PASSWORD_RESET_EMAIL,
  REQ_CONFIRM_PASSWORD_RESET,
  RCV_CONFIRM_PASSWORD_RESET,
  ERR_CONFIRM_PASSWORD_RESET,
  REQ_SEND_EMAIL_VERIFICATION,
  RCV_SEND_EMAIL_VERIFICATION,
  ERR_SEND_EMAIL_VERIFICATION,
  REQ_SIGN_IN_CUSTOM,
  RCV_SIGN_IN_CUSTOM,
  ERR_SIGN_IN_CUSTOM,
  REQ_GET_ADMIN_STATUS,
  RCV_GET_ADMIN_STATUS,
  ERR_GET_ADMIN_STATUS,
  SET_USER,
  SET_ADMIN_STATUS,
  SET_INITIALIZED,
  REQ_SIGN_UP,
  RCV_SIGN_UP,
  ERR_SIGN_UP,
} from './reducer';

import config from './config';
import { RequestError } from '../../utils/request-state';
import { User } from '../../@types/user';

if (typeof window !== 'undefined') {
  initializeApp(config);
  const auth = getAuth();
  setPersistence(auth, browserSessionPersistence);

  //firebase.initializeApp(config).auth().setPersistence(firebase.auth.Auth.Persistence.SESSION);
}

interface AuthProviderProps {
  children: React.ReactNode;
}

const AuthProvider = ({ children }: AuthProviderProps) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const getUser = useCallback(async (): Promise<void> => {
    const token = await getToken();
    if (token) {
      try {
        dispatch({ type: REQ_GET_USER });
        const response = await fetch(`${process.env.GATSBY_FUNCTIONS_URL}/getUser`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.status === 200) {
          const user: User = await response.json();
          dispatch({ type: RCV_GET_USER, user });
        } else {
          dispatch({ type: ERR_GET_USER });
        }
      } catch (e) {
        dispatch({ type: ERR_GET_USER });
      }
    }
  }, [state]);

  const getToken = async (): Promise<string | null> => {
    if (state.firebaseUser) {
      return await state.firebaseUser.getIdToken();
    }
    return null;
  };

  const signIn = useCallback(async (email: string, password: string) => {
    try {
      dispatch({ type: REQ_SIGN_IN });

      const auth = getAuth();
      const response = await signInWithEmailAndPassword(auth, email, password);
      //const response = await firebase.auth().signInWithEmailAndPassword(email, password);.
      dispatch({ type: RCV_SIGN_IN, firebaseUser: response.user || undefined });
    } catch (e) {
      dispatch({ type: ERR_SIGN_IN, error: e as RequestError | undefined });
    }
  }, []);

  const signUp = useCallback(
    async (email: string, password: string, fName: string, lName: string) => {
      try {
        dispatch({ type: REQ_SIGN_UP });
        // const response = await firebase.auth().createUserWithEmailAndPassword(email, password);
        const auth = getAuth();
        const response = await createUserWithEmailAndPassword(auth, email, password);
        await updateDisplayName(`${fName} ${lName}`);
        await sendEmailVerification();
        dispatch({ type: RCV_SIGN_UP, firebaseUser: response.user || undefined });
      } catch (e) {
        dispatch({ type: ERR_SIGN_UP, error: e as RequestError | undefined });
      }
    },
    []
  );

  const signOut = useCallback(async () => {
    try {
      dispatch({ type: REQ_SIGN_OUT });
      const auth = getAuth();
      await auth.signOut();
      //await firebase.auth().signOut();
      dispatch({ type: RCV_SIGN_OUT });
    } catch {
      dispatch({ type: ERR_SIGN_OUT });
    }
  }, []);

  const updatePassword = useCallback(async (newPassword: string) => {
    try {
      dispatch({ type: REQ_UPDATE_PASSWORD });
      const auth = getAuth();
      const user = auth.currentUser;
      if (!user) throw 'no user';
      await updatePasswordFirebase(user, newPassword);
      // await firebase.auth().currentUser?.updatePassword(newPassword);
      dispatch({ type: RCV_UPDATE_PASSWORD });
    } catch (e) {
      dispatch({ type: ERR_UPDATE_PASSWORD, error: e as RequestError | undefined });
    }
  }, []);

  const updateDisplayName = useCallback(async (displayName: string) => {
    try {
      dispatch({ type: REQ_UPDATE_DISPLAY_NAME });
      const auth = getAuth();
      const user = auth.currentUser;
      if (!user) throw 'no user';
      await updateProfile(user, { displayName });
      //await firebase.auth().currentUser?.updateProfile({ displayName });
      dispatch({ type: RCV_UPDATE_DISPLAY_NAME });
    } catch {
      dispatch({ type: ERR_UPDATE_DISPLAY_NAME });
    }
  }, []);

  const sendPasswordResetEmail = useCallback(async (email: string) => {
    try {
      dispatch({ type: REQ_PASSWORD_RESET_EMAIL });

      const auth = getAuth();
      await sendPasswordResetEmailFirebase(auth, email);
      // await firebase.auth().sendPasswordResetEmail(email);
      dispatch({ type: RCV_PASSWORD_RESET_EMAIL });
    } catch {
      dispatch({ type: ERR_PASSWORD_RESET_EMAIL });
    }
  }, []);

  const confirmPasswordReset = useCallback(async (code: string, password: string) => {
    try {
      dispatch({ type: REQ_CONFIRM_PASSWORD_RESET });
      const auth = getAuth();
      await confirmPasswordResetFirebase(auth, code, password);
      //await firebase.auth().confirmPasswordReset(code, password);
      dispatch({ type: RCV_CONFIRM_PASSWORD_RESET });
    } catch {
      dispatch({ type: ERR_CONFIRM_PASSWORD_RESET });
    }
  }, []);

  const sendEmailVerification = useCallback(async () => {
    try {
      dispatch({ type: REQ_SEND_EMAIL_VERIFICATION });
      const auth = getAuth();
      const user = auth.currentUser;
      if (!user) throw 'no user';
      await sendEmailVerificationFirebase(user);
      // await firebase.auth().currentUser?.sendEmailVerification();
      dispatch({ type: RCV_SEND_EMAIL_VERIFICATION });
    } catch (e) {
      dispatch({ type: ERR_SEND_EMAIL_VERIFICATION, error: e as RequestError | undefined });
    }
  }, []);

  const signInCustom = useCallback(async (token: string) => {
    try {
      dispatch({ type: REQ_SIGN_IN_CUSTOM });

      const auth = getAuth();
      const response = await signInWithCustomTokenFirebase(auth, token);
      // const response = await firebase.auth().signInWithCustomToken(token);
      dispatch({ type: RCV_SIGN_IN_CUSTOM, customFirebaseUser: response.user || undefined });
    } catch {
      dispatch({ type: ERR_SIGN_IN_CUSTOM });
    }
  }, []);

  const isAdmin = useCallback(async () => {
    try {
      dispatch({ type: REQ_GET_ADMIN_STATUS });
      const auth = getAuth();
      const user = auth.currentUser;
      if (!user) throw 'no user';

      const response = await getIdTokenResult(user);
      // const response = await firebase.auth().currentUser?.getIdTokenResult();
      dispatch({
        type: RCV_GET_ADMIN_STATUS,
        admin: !!(response?.claims?.admin as boolean | undefined),
      });
    } catch {
      dispatch({ type: ERR_GET_ADMIN_STATUS });
    }
  }, []);

  /*
  const forceTokenRefresh = async (): Promise<string | undefined> =>
    firebase.auth().currentUser?.getIdToken(true);
  */

  const onAuthStateChanged = () => {
    const auth = getAuth();
    return onAuthStateChangedFirebase(auth, (user) => {
      if (user) {
        isAdmin();
        dispatch({ type: SET_USER, firebaseUser: user });
      } else {
        dispatch({ type: SET_ADMIN_STATUS, admin: false });
        dispatch({ type: SET_USER });
      }
      if (!state.initialized) {
        dispatch({ type: SET_INITIALIZED });
      }
    });

    /*
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        isAdmin();
        dispatch({ type: SET_USER, firebaseUser: user });
      } else {
        dispatch({ type: SET_ADMIN_STATUS, admin: false });
        dispatch({ type: SET_USER });
      }
      if (!state.initialized) {
        dispatch({ type: SET_INITIALIZED });
      }
    });
    */
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged();
    // Cleanup subscription on unmount
    return (): void => unsubscribe();
  }, []);

  const value: AuthContextProps = useMemo(
    () => ({
      ...state,
      getUser,
      getToken,
      signIn,
      signUp,
      signOut,
      updatePassword,
      updateDisplayName,
      sendPasswordResetEmail,
      confirmPasswordReset,
      sendEmailVerification,
      signInCustom,
    }),
    [state]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
