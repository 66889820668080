import { useEffect } from 'react';
import { navigate } from 'gatsby';
import useAuth from '../State/Auth/useAuth';

const onUserRedirect = ['/login', '/login/', '/sign-up', '/sign-up/'];
const redirectTo = '/account';

const useOnUser = () => {
  const auth = useAuth();

  useEffect(() => {
    if (!auth.initialized) {
      return;
    }
    if (auth.firebaseUser && onUserRedirect.includes(window.location.pathname)) {
      navigate(redirectTo);
    }
  }, [auth]);

  return auth;
};

export default useOnUser;
