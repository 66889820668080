import styled from 'styled-components';
import { IconButton } from '@material-ui/core';

const DrawerButton = styled(IconButton)`
  display: none;
  @media (max-width: 959px) {
    display: block;
  }
`;

export default DrawerButton;
