import React, { useReducer, useMemo, useCallback } from 'react';

import { AdminUsersData, Licenses, RedisAdminStatus } from '../../@types/Redis';
import reducer, {
  REQ_GET_ADMIN_REDIS_STATUS,
  RCV_GET_ADMIN_REDIS_STATUS,
  ERR_GET_ADMIN_REDIS_STATUS,
  initialState,
  REQ_ADMIN_UPDATE_USER,
  RCV_ADMIN_UPDATE_USER,
  ERR_ADMIN_UPDATE_USER,
  ERR_ADMIN_GET_USER,
  RCV_ADMIN_GET_USER,
  REQ_ADMIN_GET_USER,
} from './reducer';
import { RedisContext, RedisContextProps } from './RedisContext';
import { RequestError } from '../../utils/request-state';
import useAuth from '../Auth/useAuth';

interface RedisProviderProps {
  children: React.ReactNode;
}

const RedisProvider = ({ children }: RedisProviderProps) => {
  const auth = useAuth();
  const [state, dispatch] = useReducer(reducer, initialState);

  const adminGetStatus = useCallback(async (): Promise<void> => {
    const token = await auth.getToken();
    if (token) {
      try {
        dispatch({ type: REQ_GET_ADMIN_REDIS_STATUS });
        const response = await fetch(`${process.env.GATSBY_FUNCTIONS_URL}/adminRedisStatus`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.status === 200) {
          const parsedResponse: RedisAdminStatus = await response.json();
          dispatch({ type: RCV_GET_ADMIN_REDIS_STATUS, accounts: parsedResponse.accounts });
        } else {
          dispatch({ type: ERR_GET_ADMIN_REDIS_STATUS });
        }
      } catch (e) {
        dispatch({ type: ERR_GET_ADMIN_REDIS_STATUS });
      }
    }
  }, [auth]);

  const adminUpdateUser = useCallback(async (uid: string, licenses: Licenses) => {
    const token = await auth.getToken();
    if (token) {
      try {
        dispatch({ type: REQ_ADMIN_UPDATE_USER });
        const response = await fetch(`${process.env.GATSBY_FUNCTIONS_URL}/adminUpdateUser`, {
          method: 'post',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            uid,
            exclusiveLicenses: parseInt(licenses.exclusiveLicenses, 10),
            manuallySubscribedLicenses: parseInt(licenses.manuallySubscribedLicenses, 10),
          }),
        });
        if (response.status === 200) {
          dispatch({ type: RCV_ADMIN_UPDATE_USER });
        } else if (response.status === 400) {
          const error: RequestError = await response.json();
          dispatch({ type: ERR_ADMIN_UPDATE_USER, error });
        } else {
          dispatch({ type: ERR_ADMIN_UPDATE_USER });
        }
      } catch (e) {
        dispatch({ type: ERR_ADMIN_UPDATE_USER });
      }
    }
  }, []);

  const adminGetUsers = useCallback(
    async (size: number, startAfter: string, current: string): Promise<void> => {
      const token = await auth.getToken();
      if (token) {
        try {
          dispatch({ type: REQ_ADMIN_GET_USER });
          const response = await fetch(
            `${process.env.GATSBY_FUNCTIONS_URL}/adminGetUsers/${size}/${startAfter || '_'}/${
              current || '_'
            }`,
            {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
              },
            }
          );
          if (response.status === 200) {
            const usersData: AdminUsersData = await response.json();
            dispatch({ type: RCV_ADMIN_GET_USER, usersData });
          } else {
            dispatch({ type: ERR_ADMIN_GET_USER });
          }
        } catch (e) {
          dispatch({ type: ERR_ADMIN_GET_USER });
        }
      }
    },
    [auth]
  );

  const value: RedisContextProps = useMemo(
    () => ({
      ...state,
      adminGetStatus,
      adminUpdateUser,
      adminGetUsers,
    }),
    [state, auth]
  );

  return <RedisContext.Provider value={value}>{children}</RedisContext.Provider>;
};

export default RedisProvider;
