import React from 'react';

import { Typography, Link } from '@material-ui/core';

const Copyright = (): JSX.Element => (
  <Typography variant="body2" color="textSecondary" align="center">
    {'Copyright © '}
    <Link color="inherit" href="https://www.bimxplorer.com">
      Bimxplorer
    </Link>{' '}
    {new Date().getFullYear()}
    {'.'}
  </Typography>
);

export default Copyright;
