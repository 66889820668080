import { createContext } from 'react';
import { AdminUsersData, Licenses, RedisAccount } from '../../@types/Redis';
import { RequestState } from '../../utils/request-state';

export interface RedisContextProps {
  accounts?: RedisAccount[];
  usersData?: AdminUsersData;
  requestGet: RequestState;
  requestGetUser: RequestState;
  requestUpdateUser: RequestState;
  adminGetStatus: () => void;
  adminUpdateUser: (uid: string, licenses: Licenses) => void;
  adminGetUsers: (size: number, startAfter: string, current: string) => void;
}

export const RedisContext = createContext<RedisContextProps | undefined>(undefined);
