import React from 'react';
import 'typeface-roboto';
import useOnUser from '../auth/useOnUser';

export interface App {
  children: JSX.Element | JSX.Element[];
}

const App = ({ children }: App): JSX.Element => {
  useOnUser();
  return <>{children}</>;
};

export default App;
