import React from 'react';
import { Link } from 'gatsby';
import { List, ListItem, ListItemText, ListItemIcon, Divider } from '@material-ui/core';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import LockOpenIcon from '@material-ui/icons/LockOpen';

import { LinkItem } from '.';
import Drawer from '../Drawer';
import PreList from '../List/PreList';
import useAuth from '../../State/Auth/useAuth';

interface Mobile {
  open: boolean;
  links: LinkItem[];
  setOpen: (open: boolean) => void;
}

const Mobile = ({ open, links, setOpen }: Mobile): JSX.Element => {
  const auth = useAuth();
  return (
    <Drawer open={open} onClose={(): void => setOpen(false)}>
      <PreList>
        <List>
          {links.map(({ title, to, icon }) => (
            <Link
              key={to}
              to={to}
              style={{
                color: 'inherit',
                textDecoration: `none`,
              }}
            >
              <ListItem button onClick={(): void => setOpen(false)}>
                <ListItemIcon>{icon}</ListItemIcon>
                <ListItemText primary={title} />
              </ListItem>
            </Link>
          ))}
        </List>
      </PreList>
      <Divider />
      <PreList>
        <List>
          {auth.firebaseUser ? (
            <>
              <Link
                to="/account"
                style={{
                  color: 'inherit',
                  textDecoration: `none`,
                }}
              >
                <ListItem button onClick={(): void => setOpen(false)}>
                  <ListItemIcon>
                    <AccountCircleIcon />
                  </ListItemIcon>
                  <ListItemText primary="Account" />
                </ListItem>
              </Link>
              <Link
                to=""
                style={{
                  color: 'inherit',
                  textDecoration: `none`,
                }}
              >
                <ListItem
                  button
                  onClick={(): void => {
                    auth.signOut();
                    setOpen(false);
                  }}
                >
                  <ListItemIcon>
                    <ExitToAppIcon />
                  </ListItemIcon>
                  <ListItemText primary="Sign out" />
                </ListItem>
              </Link>
            </>
          ) : (
            <Link
              to="/login"
              style={{
                color: 'inherit',
                textDecoration: `none`,
              }}
            >
              <ListItem button onClick={(): void => setOpen(false)}>
                <ListItemIcon>
                  <LockOpenIcon />
                </ListItemIcon>
                <ListItemText primary="Sign in" />
              </ListItem>
            </Link>
          )}
        </List>
      </PreList>
    </Drawer>
  );
};

export default Mobile;
