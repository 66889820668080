import React, { SyntheticEvent } from 'react';
import { navigate } from 'gatsby';
import { Link as MaterialUiLink } from '@material-ui/core';
import styled from 'styled-components';

const NoTextDecorationLink = styled(MaterialUiLink)`
  text-decoration: none;

  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
  }
`;

const Link = ({ children, noTextDecoration, ...props }: any): JSX.Element => {
  const onClick = (e: SyntheticEvent<HTMLAnchorElement>): void => {
    if (props.onClick) {
      props.onClick();
    }
    if (props.href && props.target !== '_blank') {
      e.preventDefault();
      navigate(props.href);
    }
  };

  if (noTextDecoration) {
    return (
      <NoTextDecorationLink {...props} onClick={onClick}>
        {children}
      </NoTextDecorationLink>
    );
  }

  return (
    <MaterialUiLink {...props} onClick={onClick}>
      {children}
    </MaterialUiLink>
  );
};

export default Link;
