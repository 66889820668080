import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import AuthProvider from '../State/Auth/AuthProvider';
import RedisProvider from '../State/Redis/RedisProvider';
import App from './app';
import Layout from './layout';

const queryClient = new QueryClient();

interface Base {
  children: JSX.Element;
}

const Base = ({ children }: Base): JSX.Element => (
  <AuthProvider>
    <RedisProvider>
      <QueryClientProvider client={queryClient}>
        <App>
          <Layout>{children}</Layout>
        </App>
      </QueryClientProvider>
    </RedisProvider>
  </AuthProvider>
);

export default Base;
